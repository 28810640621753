const initialState = {
  cameraStream: null,
  micStream: null
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'SET_CAMERA_STREAM': {
      return { ...state, cameraStream: payload };
    }

    case 'SET_MIC_STREAM': {
      return { ...state, micStream: payload };
    }

    default: {
      return state;
    }
  }
};
