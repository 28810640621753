import React from 'react';
import logo from '../../images/logo.png';
import styles from './index.module.scss';

export default function Nav() {
  return (
    <div className={styles.nav}>
      <div className={styles.logo}>
        <img src={logo} alt="Meshstream"></img>
        <h1>P2P</h1>
      </div>
    </div>
  );
}
