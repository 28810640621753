import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import StreamControllerButtons from '../../StreamControllerButtons';

import styles from './index.module.scss';

export default function Preview() {
  const { me } = useSelector((state) => state);
  const videoRef = useRef();

  useEffect(() => {
    if (me.cameraStream) {
      videoRef.current.srcObject = me.cameraStream;
    }
  }, [me.cameraStream]);

  return (
    <section className={styles.preview}>
      <div className={styles.aspectRatioContainer}>
        <div className={styles.video}>{me.cameraStream ? <video ref={videoRef} autoPlay muted></video> : null}</div>
      </div>
      <StreamControllerButtons />
    </section>
  );
}
