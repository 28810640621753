import React, { useRef, useEffect } from 'react';
import styles from './index.module.scss';

export default function PeerCard({ stream, peerInfo }) {
  const videoRef = useRef();

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);

  if (!stream) return null;

  return (
    <div className={styles.peerCard}>
      <div className={styles.videoContainer}>
        <video ref={videoRef} autoPlay playsInline />
      </div>
      <h2 className={styles.name}>
        {peerInfo?.name}({peerInfo.peerId})
      </h2>
    </div>
  );
}
