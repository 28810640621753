import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import styles from './index.module.scss';

export default function Form() {
  const navigate = useNavigate();
  const [room, setRoom] = useState('');
  const [name, setName] = useState('Guest');

  function handleJoin() {
    if (!room || !name) return alert('請填寫表單');
    navigate(`/${room}?name=${name}`, { replace: true });
  }

  return (
    <div className={styles.form}>
      <h1>MESHUB</h1>
      <div className="mt-4">
        <label htmlFor="room" className="block">
          Room:
        </label>
        <InputText id="room" aria-describedby="room" value={room} onChange={(e) => setRoom(e.target.value)} autoFocus />
      </div>

      <div className="mt-4">
        <label htmlFor="name" className="block">
          Name:
        </label>
        <InputText id="name" aria-describedby="name" value={name} onChange={(e) => setName(e.target.value)} />
      </div>
      <Button label="Join" className={styles.customButton} onClick={handleJoin} />
    </div>
  );
}
