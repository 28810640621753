import React from 'react';
import Preview from '../../components/Lobby/Preview';
import Form from '../../components/Lobby/Form';
import styles from './index.module.scss';

export default function Entry() {
  return (
    <div className={styles.lobby}>
      <main>
        <section className={styles.preview}>
          <Preview />
        </section>
        <section className={styles.form}>
          <Form />
        </section>
      </main>
    </div>
  );
}
