import React from 'react';

const filterKeys = [
  'id',
  'type',
  'kind',
  'bytesSent',
  'bytesReceived',
  'frameWidth',
  'frameHeight',
  'framesPerSecond',
  'encoderImplementation',
  'decoderImplementation',
  'totalPacketSendDelay',
  'jitterBufferDelay',
  'jitterBufferEmittedCount'
];

function Stats({ stats }) {
  return (
    <div>
      {filterKeys.map((key) =>
        stats[key] ? (
          <div key={key}>
            {key}: {stats[key]}
          </div>
        ) : null
      )}
      {stats.jitterBufferDelay && stats.jitterBufferEmittedCount ? (
        <div>
          jitterBufferDelay / jitterBufferEmittedCount: {stats.jitterBufferDelay / stats.jitterBufferEmittedCount}
        </div>
      ) : null}
    </div>
  );
}

export default Stats;
