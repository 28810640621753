import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import Peer from 'simple-peer';

import { Signal } from '../../utils/Signal-browser';
// import PeerList from '../../components/PeerList';
import PeerCard from '../../components/PeerCard';
import Stats from '../../components/Stats/Stats';
import styles from './index.module.scss';

const peerId = Math.random().toString(16).substr(2, 8);
let nats;
let hasInit = false;
const constraint = { video: true, audio: true };

const Room = () => {
  const { roomId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [peerList, setPeerList] = useState({});
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [remotePeerInfo, setRemotePeerInfo] = useState(null);
  const [rtcReports, setRtcReports] = useState([]);

  const name = searchParams.get('name');
  const caller = useRef(null);

  useEffect(() => {
    if (!localStream) {
      getLocalMediaStream();
    }
  }, []);

  useEffect(() => {
    console.log('params', roomId);

    if (!hasInit && localStream) {
      hasInit = true;
      initNats(peerId);
    }
  }, [localStream]);

  async function initNats(peerId) {
    let peerList = {};
    nats = await Signal.createNATS({
      servers: 'wss://meshub-tesl.meshstream.io:4223',
      debug: true
    });

    nats.setMessageListener(async (channel, message) => {
      console.log(`my NATS msg handler:${channel},${message}`);
      const { action, data } = message;

      switch (action) {
        case 'requestOffer': {
          const { recipientId, recipientName, recipientSignal } = data;

          if (recipientId === peerId) return;

          console.log('recipientId', recipientId);
          createCaller(recipientId);
          peerList = { ...peerList, [recipientId]: data };
          setPeerList(() => peerList);

          break;
        }

        case 'requestAnswer': {
          const { callerId, callerName, callerSignal } = data;
          const peer = new Peer({
            initiator: false,
            trickle: false,
            stream: localStream
          });

          console.log('peer', peer);
          peer.on('signal', (recipientSignal) => {
            console.log('signal 啊啊啊', recipientSignal);
            nats.publish(`room.${roomId}.peer.${callerId}`, {
              action: 'responseAnswer',
              data: { recipientId: peerId, recipientName: name, recipientSignal }
            });
            setRemotePeerInfo(() => ({
              peerId: callerId,
              name: callerName
            }));
          });

          peer.on('connect', () => {
            console.log('connect');
          });

          peer.on('stream', (stream) => {
            console.log('stream', stream);

            setRemoteStream(() => stream);
          });

          peer.on('close', (data) => {
            console.log('close', data);
          });

          peer.on('error', (error) => {
            console.log('error', error);
          });

          peer.on('data', (data) => {
            console.log('data', data);
          });

          peer.signal(callerSignal);

          peer._pc.onaddstream = () => {
            console.log('哇哈ㄏㄚ');
          };

          caller.current = peer;

          setInterval(() => {
            getStats(peer);
          }, 3000);
          break;
        }
      }
    });

    nats.subscribe(`room.${roomId}`);
    nats.subscribe(`room.${roomId}.peer.${peerId}`);

    nats.publish(`room.${roomId}`, { action: 'requestOffer', data: { recipientId: peerId, recipientName: name } });
  }

  async function getStats(peer) {
    // statreports can come with a value array instead of properties
    const flattenValues = (report) => {
      if (Object.prototype.toString.call(report.values) === '[object Array]') {
        report.values.forEach((value) => {
          Object.assign(report, value);
        });
      }
      return report;
    };

    // Promise-based getStats() (standard)
    if (peer._pc.getStats.length === 0) {
      const stats = await peer._pc.getStats(null);
      const reports = [];
      stats.forEach((report) => {
        if (report.id.includes('RTCInboundRTPVideoStream') || report.id.includes('RTCOutboundRTPVideoStream')) {
          reports.push(flattenValues(report));
        }
      });
      console.log('[...rtcReports, flattenValues(report)]', [...rtcReports, ...reports]);
      setRtcReports(() => [...rtcReports, ...reports]);
      // Single-parameter callback-based getStats() (non-standard)
    } else if (peer._pc.getStats.length > 0) {
      const stats = await peer._pc.getStats(null);

      stats.result().forEach((result) => {
        const report = {};
        if (result.type === 'video') {
          result.names().forEach((name) => {
            report[name] = result.stat(name);
          });
          report.id = result.id;
          report.type = result.type;
          report.timestamp = result.timestamp;

          setRtcReports(() => [...rtcReports, report]);
        }
      });

      // Unknown browser, skip getStats() since it's anyone's guess which style of
      // getStats() they implement.
    } else {
      console.log('nothing');
    }
  }

  async function getLocalMediaStream() {
    const mediaStream = await navigator.mediaDevices.getUserMedia(constraint);
    setLocalStream(() => mediaStream);

    return mediaStream;
  }

  async function addTrack() {
    const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const audioTrack = audioStream.getAudioTracks()[0];
    localStream.addTrack(audioTrack);
    caller.current.addTrack(audioTrack, localStream);
  }

  async function addStream() {
    const audioStream = await navigator.mediaDevices.getDisplayMedia({ video: true, audio: true });
    // const audioTrack = audioStream.getAudioTracks()[0];
    // localStream.addTrack(audioTrack);
    caller.current.addStream(audioStream);
  }

  async function createCaller(remotePeerId) {
    const peer = new Peer({
      initiator: true,
      trickle: false,
      stream: localStream
    });

    peer.on('signal', (callerSignal) => {
      nats.publish(`room.${roomId}.peer.${remotePeerId}`, {
        action: 'requestAnswer',
        data: { callerId: peerId, callerName: name, callerSignal }
      });
    });

    peer.on('stream', (stream) => {
      console.log('stream', stream);

      setRemoteStream(() => stream);
    });

    peer.on('connect', () => {
      console.log('connect');
    });

    peer.on('track', (track) => {
      console.log('track', track);
    });

    peer.on('close', () => {
      console.log('close');
    });

    peer.on('error', (error) => {
      console.log('error', error.code);
    });

    peer.on('data', (data) => {
      console.log('data', data);
    });

    peer._pc.onaddstream = () => {
      console.log('哇哈ㄏㄚ');
    };

    nats.setMessageListener(async (channel, message) => {
      console.log(`my NATS msg handler:${channel},${message}`);
      const { action, data } = message;

      switch (action) {
        case 'responseAnswer': {
          const { recipientId, recipientName, recipientSignal } = data;

          console.log('caller 收到', data);

          setRemotePeerInfo({ peerId: recipientId, name: recipientName });
          peer.signal(recipientSignal);
          break;
        }
      }
    });

    caller.current = peer;
    setInterval(() => {
      getStats(peer);
    }, 3000);
  }

  // function removeStream() {
  //   caller.current.removeStream(localStream);
  // }

  return (
    <div className={styles.room}>
      <div className={styles.videos}>
        <div className={styles.localPeer}>
          <PeerCard stream={localStream} peerInfo={{ name, peerId }} />
        </div>
        {remotePeerInfo ? (
          <div className={styles.remotePeer}>
            <PeerCard stream={remoteStream} peerInfo={remotePeerInfo} />
          </div>
        ) : null}
      </div>
      {/* <button onClick={removeStream}>removeStream</button>
      <button onClick={addStream}>addStream</button> */}
      {/* <button onClick={addStream}>addStream</button> */}
      {/* <button onClick={addTrack}>add audio track</button> */}
      {/* <button onClick={removeStream}>remove audio</button> */}
      <div className={styles.stats}>
        {rtcReports.map((report) => (
          <Stats key={report.id} stats={report} />
        ))}
      </div>
    </div>
  );
};

export default Room;
