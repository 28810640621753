import * as NATS from '../../node_modules/nats.ws/lib/src/mod';

class Signal {
  static async createNATS(connectOptions) {
    console.log(`nats setup`);
    let signal = new Signal('nats');
    const sc = NATS.JSONCodec();
    signal.nats = {
      nc: await NATS.connect(connectOptions),
      sc: NATS.JSONCodec()
    };

    signal.publish = (channel, msg) => {
      signal.nats.nc.publish(channel, signal.nats.sc.encode(msg));
    };

    signal.subscribe = (channel) => {
      console.log('即將 subscribe', channel);
      let NATS_subscribe_handler = async (subscriber) => {
        let subject = subscriber.getSubject();
        for await (const m of subscriber) {
          console.log(`[${subscriber.getProcessed()}]: ${sc.decode(m.data)}`);
          if (signal.messageCB) {
            signal.messageCB(m.subject, sc.decode(m.data));
          }
        }
      };

      let s = signal.nats.nc.subscribe(channel);
      NATS_subscribe_handler(s);
    };

    return signal;
  }

  constructor(provider) {
    this.defaultMessageCB = (channel, msg) => {
      console.log(`default message handler: provider=${provider}, channel=${channel}, msg=${msg}`);
    };
    this.messageCB = this.defaultMessageCB;
    this.provider = provider;
  }

  getProvider() {
    return this.provider;
  }
  setMessageListener(fn) {
    this.messageCB = fn;
  }
}

export { Signal };
