import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Lobby from './Pages/Lobby';
import SimplePeerRoom from './Pages/SimplePeerRoom';
import Nav from './components/Nav';
import styles from './App.module.scss';

function App() {
  return (
    <div className={styles.app}>
      <Nav />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Lobby />}></Route>
          <Route path="/:roomId" element={<SimplePeerRoom />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
