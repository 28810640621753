import React from 'react';
import { useSelector } from 'react-redux';
import useStream from '../../hooks/useStream';
import cameraOffIcon from '../../images/camera_off.png';
import cameraOnIcon from '../../images/camera_on.png';
import micOnIcon from '../../images/mic_on.png';
import micOffIcon from '../../images/mic_off.png';

import styles from './index.module.scss';

export default function StreamControllerButtons() {
  const { getCameraStream, getMicStream, stopStream } = useStream();
  const { me } = useSelector((state) => state);

  function closeCamera() {
    stopStream('camera');
  }

  function closeMic() {
    stopStream('mic');
  }

  return (
    <div className={styles.buttons}>
      {me.cameraStream ? (
        <div className={styles.button} onClick={closeCamera}>
          <img src={cameraOnIcon} />
        </div>
      ) : (
        <div className={styles.button} onClick={getCameraStream}>
          <img src={cameraOffIcon} />
        </div>
      )}
      {me.micStream ? (
        <div className={styles.button} onClick={closeMic}>
          <img src={micOnIcon} />
        </div>
      ) : (
        <div className={styles.button} onClick={getMicStream}>
          <img src={micOffIcon} />
        </div>
      )}
    </div>
  );
}
