import { useSelector, useDispatch } from 'react-redux';
import { setCameraStream, setMicStream } from '../redux/actions/me';

export default function () {
  const dispatch = useDispatch();
  const { me } = useSelector((state) => state);

  async function getCameraStream() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      dispatch(setCameraStream(stream));
    } catch (error) {
      _handleGetStreamError(error);
    }
  }

  async function getMicStream() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      dispatch(setMicStream(stream));
    } catch (error) {
      _handleGetStreamError(error);
    }
  }

  function stopStream(kind) {
    if (kind === 'camera') {
      _stopTracks(me.cameraStream);
      return dispatch(setCameraStream(null));
    }
    if (kind === 'mic') {
      _stopTracks(me.micStream);
      return dispatch(setMicStream(null));
    }
    if (kind === 'screen') {
    }
  }

  return {
    getCameraStream,
    getMicStream,
    stopStream
  };
}

function _handleGetStreamError(error) {
  console.log(error.name);
  if (error.name === 'NotFoundError' || error.name === 'DevicesNotFoundError') {
    //required track is missing
    alert('DevicesNotFoundError');
  } else if (error.name === 'NotReadableError' || error.name === 'TrackStartError') {
    //webcam or mic are already in use
    alert('NotReadableError');
  } else if (error.name === 'OverconstrainedError' || error.name === 'ConstraintNotSatisfiedError') {
    //constraints can not be satisfied by avb. devices
    alert('OverconstrainedError');
  } else if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
    //permission denied in browser
    alert('PermissionDeniedError');
  } else if (error.name === 'TypeError' || error.name === 'TypeError') {
    //empty constraints object
    alert('TypeError');
  } else {
    //other errors
    alert('GetStream Error');
  }
}

function _stopTracks(stream) {
  const tracks = stream.getTracks();
  tracks.map((track) => track.stop());
}
